import React from 'react';
import { DappUI, useGetLoginInfo } from '@elrondnetwork/dapp-core';
import { routeNames } from 'routes';

import { Modal } from "react-bootstrap";

export const UnlockRoute: () => JSX.Element = () => {
  const {
    ExtensionLoginButton,
    WebWalletLoginButton,
    LedgerLoginButton,
    WalletConnectLoginButton
  } = DappUI;
  const { isLoggedIn } = useGetLoginInfo();

  React.useEffect(() => {
    if (isLoggedIn) {
      window.location.href = routeNames.dashboard;
    }
  }, [isLoggedIn]);

  return (
    <div className='home d-flex flex-fill align-items-center'>
      <div className='m-auto' data-testid='unlockPage'>
        <div className='card my-4 text-center'>
          <div className='card-body py-4 px-2 px-sm-2 mx-lg-4'>
            <h4 className='mb-4'>Login</h4>
            <p className='mb-4'>pick a login method</p>

            <ExtensionLoginButton
              callbackRoute={routeNames.dashboard}
              loginButtonText={'Extension'}
            />
            <WebWalletLoginButton
              callbackRoute={routeNames.dashboard}
              loginButtonText={'Web wallet'}
            />
            <LedgerLoginButton
              loginButtonText={'Ledger'}
              callbackRoute={routeNames.dashboard}
              className={'test-class_name'}
            />
            <WalletConnectLoginButton
              callbackRoute={routeNames.dashboard}
              loginButtonText={'Maiar'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

interface UnlockModalProps {
  show: boolean,
  close: () => void
}
export const UnlockModal = ({show, close}: UnlockModalProps) => {
   const {
    ExtensionLoginButton,
    WebWalletLoginButton,
    LedgerLoginButton,
    WalletConnectLoginButton
  } = DappUI;
  const { isLoggedIn } = useGetLoginInfo();

  React.useEffect(() => {
    if (isLoggedIn) {
      // window.location.href = routeNames.dashboard;
      close();
    }
  }, [isLoggedIn]);

  return (
    <Modal
      show={show}
      onHide={close}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <h4 className='mb-4'>Login</h4>
      </Modal.Header>
      <Modal.Body>
        <p className='mb-4'>Pick a login method :</p>

        <div className="d-flex justify-content-around">
          <ExtensionLoginButton
            // callbackRoute={routeNames.dashboard}
            redirectAfterLog={false}
            redirectAfterLogin={false}
            shouldRedirect={false}
            loginButtonText={'Extension'}
          />
          <WebWalletLoginButton
            // callbackRoute={routeNames.dashboard}
            redirectAfterLog={false}
            redirectAfterLogin={false}
            shouldRedirect={false}
            loginButtonText={'Web wallet'}
          />
          <LedgerLoginButton
            loginButtonText={'Ledger'}
            // callbackRoute={routeNames.dashboard}
            redirectAfterLog={false}
            redirectAfterLogin={false}
            shouldRedirect={false}
            className={'test-class_name'}
          />
          <WalletConnectLoginButton
            // callbackRoute={routeNames.dashboard}
            redirectAfterLog={false}
            redirectAfterLogin={false}
            shouldRedirect={false}
            loginButtonText={'Maiar'}
          />
        </div>
      </Modal.Body>
    </Modal>
  ); 
};

export default UnlockRoute;
