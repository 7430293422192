import axios from 'axios';

interface GetLatestTransactionsType {
  apiAddress: string;
  address: string;
  contractAddress: string;
  timeout: number;
  page?: number;
  url?: string;
}

interface GetNftType {
  apiAddress: string;
  address: string;
  timeout: number;
  limit: number;
  page?: number;
  url?: string;
}

const fetchTransactions = (url: string) =>
  async function getTransactions({
    apiAddress,
    address,
    contractAddress,
    timeout
  }: GetLatestTransactionsType) {
    try {
      const { data } = await axios.get(`${apiAddress}${url}`, {
        params: {
          sender: address,
          receiver: contractAddress,
          condition: 'must',
          size: 25
        },
        // timeout
      });

      return {
        data: data,
        success: data !== undefined
      };
    } catch (err) {
      console.log(err)
      return {
        success: false
      };
    }
  };

export const getTransactions = fetchTransactions('/transactions');
export const getTransactionsCount = fetchTransactions('/transactions/count');

const fetchNfts = (url: string) =>
  async function getNft({
    apiAddress,
    address,
    limit,
    timeout
  }: GetNftType) {
    try {
      const { data } = await axios.get(`${apiAddress}/accounts/${address}${url}`, {
        params: {
          from:0,
          size:limit,
          type:"NonFungibleESDT",
          timeout
        },
      });

      return {
        data: data,
        success: data !== undefined
      };
    } catch (err) {
      console.log(err)
      return {
        success: false
      };
    }
  };

export const getNfts = fetchNfts('/nfts');

