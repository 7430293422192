import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { dAppName } from 'config';
import { routeNames } from 'routes';
import {
  transactionServices,
  useGetAccountInfo,
  useGetPendingTransactions,
  refreshAccount,
  useGetNetworkConfig
} from '@elrondnetwork/dapp-core';
import {
  Address,
  AddressValue,
  ContractFunction,
  ProxyProvider,
  Query,
  I32Value,
} from '@elrondnetwork/erdjs';
import * as PIXI from "pixi.js";
import { Stage, Sprite } from "@inlet/react-pixi";

import { 
  collection,
  contractAddress,
  XMAX,
  YMAX,
  WIDTH,
  OFFSET,
  HEIGHTWINDOW,
  WIDTHWINDOW,
} from "config";

import Viewport from "./Viewport";
import MapSprites from "./MapSprites";
import {
  Point,
  TileType,
  NFH,
} from "./helpers/types";
import {
  valToHex,
} from "./helpers/functions"
import { 
  getNbNFTs,
  getNFTs,
  fetchTiles,
} from "./helpers/asyncRequests";
import SideInfos from "./SideInfos";

import { computeMaxSubMatrix } from "./algorithms";

import { decodeBase64 } from "./helpers/functions";

type TilesHashmap = {
  [key: string]: TileType;
};


const Home = () => {
  const [myTile, setMyTile] = useState<{ [key: string]: NFH }>({});
  const [tiles, setTiles] = useState<{ [key: string]: TileType }>();
  const [emptyTiles, setEmptyTiles] = useState<string[]>([]);


  const [loading, setLoading] = useState<string>("Constructing the map...");
  const [minted, setMinted] = useState<number>(0);

  const account = useGetAccountInfo();
  const { address } = account;
  const {network: { apiAddress }} = useGetNetworkConfig();

  const [selected, setSelected] = useState<{point: Point, tile?: TileType}>();

  useEffect(() => {
      const fetchUserNFT = async () => {
        let nbNFT = 10000;
        const size = await getNbNFTs({
          apiAddress,
          address: account.address,
          timeout: 3000,
          params: {type:"NonFungibleESDT",collection:collection},
        });
        if (size.success && size.data){nbNFT = size.data;}
      
        const resEsdts = await getNFTs({
          apiAddress,
          address: account.address,
          timeout: 3000,
          params: {from:0,size:nbNFT,type:"NonFungibleESDT",collection:collection},
        });

        const addressTile: { [key: string]: NFH } = {};
        if (resEsdts.success && resEsdts.data){
          for (let i = 0; i < resEsdts.data.length; i++){
            if (resEsdts.data[i].collection === collection){
              const coord = decodeBase64(resEsdts.data[i].attributes).split(";");
              const x = Number(coord[0].substring(2));
              const y = Number(coord[1].substring(2));
              const content = {
                  x: x,
                  y: y,
                  name: resEsdts.data[i].name,
                  nonce: Number(resEsdts.data[i].nonce),
                  url: decodeBase64(resEsdts.data[i].uris[0]),
                };
              addressTile[`${x}x${y}`] = content;
            }
          }
        }
        setMyTile(addressTile);
        console.log("mytiles : "+Object.keys(addressTile).length);
      };

      const populateTiles = async () => {
        setLoading("Fetching the NFTs...");
        let data: any[] = [];
        const promises = [];
  
        /** Promise fteching data **/
        for(let i = 0; i < 100; i+=2){promises.push(fetchTiles(new I32Value(2),new I32Value(i),apiAddress));}
        try{
          const result = await Promise.allSettled<any[]>(promises);
          result.map( async (promise,n) => {
            if (promise.status === "fulfilled"){
                if (promise?.value?.success && promise?.value?.data)
                  data = data.concat(promise.value.data);
                else
                  console.log("Error SC request");
            }else
              console.log("Error fetchTile");
          });
        }catch(e){
          console.log("Catch Error SC exec")
        }
  
        /** Format data fetch **/
        const every_nft: { [key: string]: TileType } = {};
        const emptyTilesTemp: string[] = [];
        let nbImage = 0;
        for (let i = 0; i < data.length; i += 6 ){
          let x = Number("0x"+Buffer.from(data[i], "base64").toString("hex"));
          let y = Number("0x"+Buffer.from(data[i+1], "base64").toString("hex"));
          let owner = Buffer.from(data[i+2], "base64").toString("hex");
          let type = Number("0x"+Buffer.from(data[i+3], "base64").toString("hex"));
          let content = Buffer.from(data[i+4], "base64").toString();
          let href = Buffer.from(data[i+5], "base64").toString();
          x = x ? x : 0;y = y ? y : 0;type = type ? type : 0;
          if (content) {
            if ( !(`${owner}${content}` in every_nft) )
              every_nft[`${owner}${content}`] = {points: {},x: -1,y: -1,w:0,type,content,owner,href};
            every_nft[`${owner}${content}`].points[`${x}x${y}`] = true;
            nbImage++;
            emptyTilesTemp.push(`${x}x${y}`);
          }
        }
        setMinted(nbImage);
        setEmptyTiles(emptyTilesTemp)
        /** Calculating the matrix **/
        setLoading("Calculating the matrix");
        let limit = 0;
        const final: { [key: string]: TileType } = {};
        for (const n in every_nft) {
          if (n) // rm invalid points
            for (let i in every_nft[n].points) {
              let [x, y] = i.split("x");
              if ( 0 > Number(x) || Number(x) > 100 || 0 > Number(y) || Number(y) > 100 ) {
                console.log("aborted", `${x}x${y}`)
                delete every_nft[n].points[`${x}x${y}`];
              }
            }
          while (n && Object.keys(every_nft[n].points).length > 0 && limit < 1000) {
            limit++;
            try{
              const r = computeMaxSubMatrix(every_nft[n].points);
              final[`${r.x}x${r.y}`] = {points:{},x: r.x,y: r.y,w: r.w,type: every_nft[n].type,content: every_nft[n].content,owner: new Address(every_nft[n].owner).toString(),href: every_nft[n].href,};
              for (let i = r.x; i <= r.x + r.w; i++)
                for (let j = r.y; j <= r.y + r.w; j++) {
                  delete every_nft[n].points[`${i}x${j}`];
                }
            }catch(error){console.error(error);}
          }
        }
        setLoading("");
        setTiles(final);
      };

      if (!tiles) populateTiles();
      if (address && myTile === {}) fetchUserNFT();
  }, [tiles,address]);

  const addTile = (x: number,y: number) => {
    if (tiles){
      let final = tiles;
      final[`${x}x${y}`] = {points:{},x: x,y: y,w: 0,type: 0,content: "000",owner: address,href: "#",};
      setTiles(final);
    }
  }

  return (
    <>
      {!tiles && loading !== "" && (
        <div className="fs-1 text-center h-100 my-auto " style={{zIndex:99}}>
          <div className="loader">
            <div className="spin"></div>
            <div className="bounce"></div>
          </div>
          <span className="text-dark fw-bold fs-3">{loading}</span>
        </div>
      )}

      <div className="container m-0 p-0">
        <div 
          style={{zIndex: "100"}}
          className="position-absolute end-0 bottom-0 p-2 px-4 m-3 rounded-pill bg-light fw-bold user-select-none">
        {minted} / 10,000 minted
        </div>

        <Stage
          style={{position: "absolute",top: 0}}
          width={window.innerWidth}
          height={window.innerHeight}
          options={{
            backgroundColor: 0x333333,
            clearBeforeRender: false,
          }}
          raf={true}
        >
          <Viewport width={WIDTHWINDOW} height={HEIGHTWINDOW}>
            <MapSprites tiles={tiles} emptyTiles={emptyTiles} setSelected={setSelected} />
          </Viewport>
        </Stage>
        <SideInfos selected={selected} close={setSelected} myTile={myTile} addTile={addTile}/>
      </div>
    </>
  );
};

export default Home;
