import React from "react";
import { Card } from "react-bootstrap";

import { RiMapPin2Line } from "react-icons/ri";
import { CgArrowsExpandRightAlt } from "react-icons/cg";

interface CardCenterProps {
    children?: React.ReactElement;
	title?: string;
    show: boolean;
    onHide: () => void;
};

const CardCenter = ({children, title, show, onHide}: CardCenterProps) => {
    const close = () => onHide();

    if (show) return (
        <div style={{
                width: "100vw",
                height: "100vh",
                left: 0,
                top: 0,
                position: "absolute",
                overflow: "hidden"
            }}
            className="d-flex align-items-center"
        >
            <div className="row w-100 d-flex justify-content-center m-0">

                <div className="col-12 col-sm-10 col-md-8 col-lg-6 p-0">
                    <Card style={{borderRadius: "24px"}} className="shadow-sm">
                        <div className="d-flex mx-3 mt-3">
                            <h2 className="brand">{title}</h2>
                            <button className="btn-close ms-auto" onClick={() => close()}></button>
                        </div>
                        <Card.Body className="my-2">
                            <Card.Text>
                            {children}

                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div> 

            </div>
        </div>
    )
    else return null;
};

export default CardCenter;