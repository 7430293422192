export const valToHex = function (val: number) { 
    let hex = val.toString(16);
    if (hex.length%2 === 1) {
        hex = "0" + hex;
    }
    return hex;
  };
  
  export const stringToHex = function (str: string) {
      let hex = "";
      if(str){
          for(let i=0;i<str.length;i++) {
              hex += ""+str.charCodeAt(i).toString(16);
          }
          if (hex.length%2 === 1) {
              hex = "0" + hex;
          }
      }
    return hex;
  };
  
  
  export const decodeBase64 = function (base64: string){
    const buff = Buffer.from(base64, "base64");
    return buff.toString("utf-8");
  };