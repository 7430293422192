
export const dAppName = "Non Fungible Homepage";

//export const contractAddress = "erd1qqqqqqqqqqqqqpgq8tkedd2rw0xp3pp353eyzccxfpkjjhlaqm7sujdv24";
//export const collection="NFH-f75732";

//export const thumbnailURL = "https://thumbnail.nonfungiblehomepage.org/";
export const thumbnailURL = "https://thumbnail.nonfungiblehomepage.org/devnet/";
export const contractAddress = "erd1qqqqqqqqqqqqqpgq8tkedd2rw0xp3pp353eyzccxfpkjjhlaqm7sujdv24";
export const collection="NFH-31aa11";
export const XMAX = 100;
export const YMAX = 100;
export const WIDTH = 50;
export const OFFSET = WIDTH/2;
export const HEIGHTWINDOW = window.innerHeight;
export const WIDTHWINDOW = window.innerWidth;