import React from "react";
import { NavItem, Nav, Form, Button,Card, FormControl, InputGroup, Row, Col, Image } from "react-bootstrap";
import { IoMdLogOut, IoMdLogIn, IoIosSearch } from "react-icons/io";
import { Link } from "react-router-dom";
import { routeNames } from "routes";
import { contractAddress,thumbnailURL } from "config";

import { valToHex, stringToHex } from "pages/Home/helpers/functions";
import CardCenter from "components/CardCenter";

import { UnlockModal } from "pages/UnlockPage";

import {
  logout,
  transactionServices,
  useGetAccountInfo,
  refreshAccount,
  useGetNetworkConfig,
} from '@elrondnetwork/dapp-core';

import { getNfts } from 'apiRequests';

export interface responseNft {
  attributes: string;
  collection: string;
  creator: string;
  identifier: string;
  isWhitelistedStorage: boolean;
  media: any;
  metadata: any;
  name: string;
  nonce: number;
  royalties: number;
  ticker: string;
  type: string;
  uris: string[];
  url: string;
}
const Navbar = () => {
  const account = useGetAccountInfo();
  const { address } = account;

  const homePage = window.location.pathname === routeNames.home;
  const profilePage = window.location.pathname === routeNames.profile;

  // const handleLogout = () => {logout(`${window.location.origin}/unlock`);};
  const handleLogout = () => {logout();};

  const { sendTransactions } = transactionServices;
  const /*transactionSessionId*/ [, setTransactionSessionId] = React.useState<
    string | null
  >(null);

  const send = async (qt: number,type: number, content: string) => {
    let dataTransaction = "mintRandomTile@"+valToHex(qt)+"@"+valToHex(type)

    if (content !== "") dataTransaction += "@"+stringToHex(content);
    const tx = {
      value: Number(100000000000000000*qt).toFixed(1)+"",
      data: dataTransaction,
      receiver: contractAddress,
      gasLimit: Math.min((20000000*qt),600000000)
    };
    await refreshAccount();

    const { sessionId /*, error*/ } = await sendTransactions({
      transactions: tx,
      transactionsDisplayInfo: {
        processingMessage: 'Processing Mint transaction',
        errorMessage: 'An error has occured during the minting',
        successMessage: 'Mint transaction successful'
      },
      redirectAfterSign: false
    });
    if (sessionId != null) {
      setTransactionSessionId(sessionId);
    }
  };


  const [quantity, setQuantity] = React.useState<number>(1);
  const [nftIdentifier, setNftIdentifier] = React.useState<string>("");
  const [btnText, setBtnText] = React.useState<string>("Confirm");
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const [showMint, setShowMint] = React.useState(false);
  const handleClose = () => setShowMint(false);
  const handleShow = () => setShowMint(true);
  
  const [type, setType] = React.useState<number>(1);
  const [color,setColor] = React.useState<string>("00AA00");
  const handleChangeColor = (val: any) => setColor(val.target.value.replace("#",""));
  const handleSelect = (eventKey: any) => setType(Number(eventKey));

  const [showLoginModal, setShowLoginModal] = React.useState<boolean>(false);

  const handleSubmit = () => {
    setIsSubmitting(true);
    setBtnText("Loading");
    if (type) send(quantity,type,nftIdentifier);
    else send(quantity,type,color);
    
    setIsSubmitting(false);
    setBtnText("Confirm");
    handleClose();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "Quantity")
      setQuantity(parseInt(Number(event.target.value).toFixed(1)));

    if (event.target.name === "Url")
    setNftIdentifier(event.target.value);
  };

	const {network: { apiAddress }} = useGetNetworkConfig();
  const [nfts,setNfts] = React.useState<responseNft[]>();
  const [searchNft,setSearchNft] = React.useState<string>("");
  const [limitNftRender,setLimitNftRender] = React.useState<number>(10);
  const [nftToRender,setNftToRender] = React.useState<responseNft[]>([]);
  const fetchNftToRender = () => {
    if (nfts){
      const tmp:responseNft[] = [];
      nfts.map((n,i)=>{
        if (n.name.search(searchNft) === 0 || n.collection.search(searchNft) === 0 || n.identifier.search(searchNft) === 0){
          tmp.push(n);
        }
      })
      setNftToRender(tmp);
    }
	};
  React.useEffect(fetchNftToRender, [nfts,searchNft]);

  const fetchData = () => {
    if (account.address){
      getNfts({
        apiAddress,
        address: account.address,
        timeout: 3000,
        limit:10000,
      }).then(({ data, success: transactionsFetched }) => {
        refreshAccount();
        setNfts(data);
      });
    }
	};
	React.useEffect(fetchData, [account.address]);

  return (
    <>
      <Nav
        className="ml-auto"
        style={{
          position: "absolute",
          zIndex: 10,
          paddingLeft: "20px"
        }}
      >
        {address ? (
          <NavItem>
            <button
              onClick={handleLogout}
              className="btn btn-light border-1 border-dark mt-3 ms-3 rounded-circle p-2 me-2"
            >
               <IoMdLogOut size="2em"/>
            </button>
            {homePage && (
              <button
              onClick={handleShow}
              className="btn btn-light border-1 border-dark mt-3 rounded-pill p-2 px-4 me-2"
            >
              Mint random
            </button>
            )}

            {!profilePage && 
              <Link
                to={routeNames.profile}
                className="btn btn-light border-1 border-dark mt-3 rounded-pill p-2 px-4"
                data-testid="loginBtn"
              >
                My tiles
              </Link>
            }
            {!homePage && 
              <Link
                to={routeNames.home}
                className="btn btn-light border-1 border-dark mt-3 rounded-pill p-2 px-4 ms-2"
              >
                Home
              </Link>
            }
          </NavItem>
        ) : (
          <NavItem>
            {homePage &&
              <>
                <button
                  onClick={() => setShowLoginModal(true)}
                  className="btn btn-light border-1 border-dark mt-3 rounded-pill p-2 px-3 me-2"
                >
                  Login <IoMdLogIn size="1.45em"/>
                </button>
                <button
                  onClick={() => setShowLoginModal(true)}
                  className="btn btn-light border-1 border-dark mt-3 rounded-pill p-2 px-4"
                >
                  Mint random
                </button>
              </>
            }
            {!homePage && 
              <Link
                to={routeNames.home}
                className="btn btn-light border-1 border-dark mt-3 rounded-pill p-2 px-4 ms-2"
              >
                Back
              </Link>
            }
          </NavItem>
        )}

        
        <CardCenter show={showMint} onHide={handleClose} title={"Mint random tile"}>
          <>
          <div className="text-center fs-4 fw-bold">
            Total cost : {Number(0.1*quantity).toFixed(1)} EGLD
          </div>
          <Form.Group controlId="validationFormik01" className="my-1 mt-4 w-auto  mx-auto">
              <Form.Label className="text-center w-100">Amount of tiles<span className="text-danger">*</span> :</Form.Label>
              <Form.Control 
                type="number" placeholder="1" 
                min="1" max="100"
                className="rounded shadow-sm text-center"
                name="Quantity"
                onChange={handleChange}
                value={quantity}
              />
          <div className="text-danger small">The quantity must be between 0 and 100</div>
          </Form.Group>

          <Form.Label className="text-center w-100">Type of content<span className="text-danger">*</span> :</Form.Label>
          <Nav variant="tabs" defaultActiveKey={type} onSelect={handleSelect} className="w-100">
            <Nav.Item className="w-50 text-center">
              <Nav.Link eventKey="0"  >Color</Nav.Link>
            </Nav.Item>
            <Nav.Item className="w-50 text-center">
              <Nav.Link eventKey="1" >NFT</Nav.Link>
            </Nav.Item>
          </Nav>
          
          { type === 0 && <Card className="bg-light border-0 shadow-sm">
            <Card.Body>
              <Card.Text>
              <Row>
                <Col xs={12} md={5}>
                  <Form.Label className="text-center ">Choose a color :</Form.Label>
                </Col>
                <Col xs={12} md={7}>
                  <input type="color" className="form-control form-control-color ms-auto w-100 my-auto" id="exampleColorInput" value={"#"+color} title="Choose your color" onChange={(v) => handleChangeColor(v)}/>
                </Col>
              </Row>
              </Card.Text>
            </Card.Body>
          </Card>
          }
          { type === 1 && 
          <Card className="bg-light border-0 shadow-sm">
            <Card.Body>
              <Card.Text>
                <InputGroup className="mb-3">
                  <FormControl placeholder="Search NFT" aria-label="Search" value={searchNft} onChange={(e) => setSearchNft(e.target.value)}/>
                  <Button variant="" ><IoIosSearch/></Button>
                </InputGroup>
                <div className="overflow-auto h-auto" style={{maxHeight: "400px"}}>
                  <Row className="m-0">
                    {nftToRender.map((n,i) => {
                        if (i < limitNftRender){
                          return (
                          <Col key={"nn"+i} xs={6} lg={4} className="p-0 d-flex justify-content-center">
                            <div className="text-center">
                              <Button variant="link" onClick={(e) => setNftIdentifier(n.identifier)}>
                                <Image id={n.identifier} className={"w-100 h-auto"+(nftIdentifier == n.identifier ? " img-thumbnail" : "")} style={{width: "150px"}} src={thumbnailURL+n.identifier} />
                              </Button>
                              <span className="w-100 fw-bold">{n.name}</span>
                            </div>
                          </Col>
                          );
                        }
                    })}
                  </Row>
                  { nfts && limitNftRender < nftToRender.length &&
                    <div className="text-center">
                      <Button onClick={() => setLimitNftRender(limitNftRender+10)}>Load more</Button>
                    </div>
                  }
                  { !nfts || nftToRender.length == 0 && <div className="text-center">No Nft found</div>}
                </div>

              </Card.Text>
            </Card.Body>
          </Card>
          }

          <div className="d-flex justify-content-end">
            <Button variant="secondary" onClick={handleClose} disabled={isSubmitting}>Cancel</Button>
            <Button variant="primary" className="ms-2" onClick={handleSubmit} disabled={isSubmitting}>{btnText}</Button>
          </div>
          </>
        </CardCenter>
      </Nav>
      <UnlockModal show={showLoginModal} close={() => setShowLoginModal(false) } />
    </>
  );
};

export default Navbar;
