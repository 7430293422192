import axios from 'axios';
import { contractAddress, collection } from "config";

import {
    Address,
    AddressValue,
    ContractFunction,
    ProxyProvider,
    Query,
    TypedValue
  } from '@elrondnetwork/erdjs';
  


interface GetLatestTransactionsType {
  apiAddress: string;
  address: string;
  contractAddress?: string;
  timeout: number;
  page?: number;
  url?: string;
  params: any;
}

const fetchNFTs = (url: string) =>
  async function getNFTs({
    apiAddress,
    address,
    timeout,
    params,
  }: GetLatestTransactionsType) {
    try {
      const { data } = await axios.get(`${apiAddress}/accounts/${address}${url}`, {
        params: params,
        timeout
      });
      return {
        data: data,
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false
      };
    }
  };

export const getNFTs = fetchNFTs('/nfts');
export const getNbNFTs = fetchNFTs('/nfts/count');


export const fetchTiles = async (nb: TypedValue,padding: TypedValue,apiAddress: any) => {
    const query = new Query({
        address: new Address(contractAddress),
        func: new ContractFunction('getMapTile'),
        args: [nb,padding]
    });
    const proxy = new ProxyProvider(apiAddress,{timeout:5000});
    try{
        const resQuery = await proxy.queryContract(query)
        if (resQuery.returnData !== undefined)
            return {success:true,data:resQuery.returnData};
        else
            return {success:false};   
    }catch(err){
        console.error('Unable to call VM query', err);
    }

};
