import { useState,useEffect } from "react";

import { 
    XMAX,
    YMAX,
    WIDTH,
    OFFSET,
    thumbnailURL,
} from "config";
import {
    Point,
    TileType,
    NFH,
} from "./helpers/types"
import { Sprite } from "@inlet/react-pixi";
import * as PIXI from "pixi.js";

interface MapSpritesEmptyProps {
    emptyTiles: string[];
    tiles: {[key: string]: TileType} | undefined;
    setSelected: (arg0?:{point: Point, tile?: TileType}) => void;
};

const MapSprites = ({tiles, emptyTiles,setSelected}: MapSpritesEmptyProps) => {

    const onClick = (x: number,y: number) => {
        if (tiles && !window.drawing){
            if(`${x}x${y}` in tiles) setSelected({"point": {x: tiles[`${x}x${y}`].x, y: tiles[`${x}x${y}`].y}, "tile": tiles[`${x}x${y}`]});
            else setSelected({"point": {x: x, y: y}, "tile": tiles[`${x}x${y}`]});
        }
    }
    return (
    <>
        {Array.from(Array(XMAX).keys()).map((x) =>
            Array.from(Array(YMAX).keys()).map((y)=> {
                if (!emptyTiles.includes(`${x}x${y}`)){
                    return (
                    <Sprite
                        key={`${x}x${y}`} 
                        x={x*50 + OFFSET}
                        y={y*50 + OFFSET}
                        texture={PIXI.Texture.WHITE}
                        tint={x%2 ? y%2 ? 0x93968F : 0x7F827B : y%2 ? 0x7F827B : 0x93968F}
                        width={WIDTH}
                        height={WIDTH}
                        cacheAsBitmap={true}
                        interactive
                        buttonMode
                        click={()=>onClick(x,y)}
                        tap={()=>onClick(x,y)}
                    />
                    )
                }else{
                    //console.log("Print tuile user")
                    if (tiles && tiles[`${x}x${y}`]){
                        if (tiles[`${x}x${y}`].type === 0){
                            return (
                                <Sprite
                                    key={`t-${x}x${y}`}
                                    texture={PIXI.Texture.WHITE}
                                    tint={Number("0x"+tiles[`${x}x${y}`].content)}
                                    width={(tiles[`${x}x${y}`].w + 1) * WIDTH}
                                    height={(tiles[`${x}x${y}`].w + 1) * WIDTH}
                                    x={tiles[`${x}x${y}`].x*50 + OFFSET}
                                    y={tiles[`${x}x${y}`].y*50 + OFFSET}
                                    interactive
                                    buttonMode
                                    click={()=>onClick(x,y)}
                                    tap={()=>onClick(x,y)}
                                />
                            )
                        }else if (tiles[`${x}x${y}`].type === 1){
                            return (
                                <Sprite
                                    key={`t-${x}x${y}`}
                                    image={`${thumbnailURL}${tiles[`${x}x${y}`].content}`}
                                    width={(tiles[`${x}x${y}`].w + 1) * WIDTH}
                                    height={(tiles[`${x}x${y}`].w + 1) * WIDTH}
                                    x={tiles[`${x}x${y}`].x*50 + OFFSET}
                                    y={tiles[`${x}x${y}`].y*50 + OFFSET}
                                    interactive
                                    buttonMode
                                    //cacheAsBitmap={true}
                                    click={()=>onClick(x,y)}
                                    tap={()=>onClick(x,y)}
                                />
                            )
                        }
                    }
                }
            }
        ))}
    </>
    )
} 

export default MapSprites;